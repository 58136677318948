/** @jsx jsx */
import { jsx } from "theme-ui"
import { BLOCKS,INLINES } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import PropTypes from "prop-types"
import Text from "./text"
import ViewMoreIcon from "../images/viewmore.png"
import React, { useState, useEffect } from "react"

const RichText = ({
  data,
  key,
  textStyle,
  listItemStyle,
  listStyle,
  listItemTextStyle,
  showBios,
  pathname=""
}) => {
  const [screenWidth, setScreenWidth] = useState("")
  useEffect(() => {
    if (checkIfWindow) {
      setWindowWidth()
    }
  }, [])
  
  const setWindowWidth = () => {
    setScreenWidth(window.screen.availWidth)
  }

  const checkIfWindow = () => {
    if (typeof window !== "undefined") {
      window.addEventListener("orientationchange", setWindowWidth)
      return typeof window !== "undefined"
    }
    return typeof window !== "undefined"
  }

  let setHeight = (checkIfWindow && screenWidth > 700) ? "360" : "60%";
  return data && data.json ? documentToReactComponents(data.json, {
    renderText: text => {
      if(pathname ==='/blog/2021-corporate-sustainability-report' && text === '--FlipBookCode--'){
        return (
          <div
            style={{ position: "relative", paddingTop: 'max(60%,326px)', height: 0, width: "100%" }}
          >
            <iframe sandbox="allow-top-navigation allow-top-navigation-by-user-activation allow-downloads allow-scripts allow-same-origin allow-popups allow-modals allow-popups-to-escape-sandbox"
              allowfullscreen="true"
              style={{ position: 'absolute', border: 'none', width: '100%', height: '100%', left: 0, right: 0, top: 0, bottom: 0 }}

              src="https://e.issuu.com/embed.html?d=plant_prefab_2021_corporate_sustainability_report&u=plantprefab">
                

            </iframe>
          </div>)
      }else{
        return text.split("\n").reduce((children, textSegment, index) => {
        return [...children, index > 0 && <br key={index} />, textSegment]
      }, [])
      }
      
    },
    renderNode: {
      [BLOCKS.HEADING_6]: (node, children) => {
        if (node.content[0].value.trim() === "Steve Glenn<icon>") {
          return (
            <div
              style={{
                display: "flex",
                marginTop: "25px",
              }}
              onClick={() => showBios()}
            >
              <Text
                customStyle={{
                  cursor: "pointer",
                  fontWeight: "bold",
                }}
              >
                {"Steve Glenn"}
              </Text>
              <img
                sx={{
                  height: 25,
                  filter:
                    "invert(17%) sepia(19%) saturate(59%) hue-rotate(315deg) brightness(99%) contrast(92%)",
                  position: "relative",
                  cursor: "pointer",
                }}
                src={ViewMoreIcon}
                alt=""
              ></img>
            </div>
          )
        } else {
          return <h6>{children}</h6>
        }
      },
      [BLOCKS.PARAGRAPH]: (node, children) => {
        return (
          <Text
            customStyle={{
              mb: theme => theme.spacing.horizontal,
              ...textStyle,
            }}
            // key={key}
          >
            {children}
          </Text>
        )
      },
      [INLINES.HYPERLINK]: (node) => {
				if (node.data.uri.indexOf('youtube.com') !== -1) {
          let url ;
          if(node.data.uri.includes("watch?v=")){
            const myArray = node.data.uri.split("watch?v=");
            url = myArray[0]+'embed/'+myArray[1];
          }else url = node.data.uri
					return (
            <div>
              <iframe
                id="ytplayer"
                src={url}
                type="text/html"
                // width="640"
                width="100%"
                height={setHeight}
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture ; fullscreen"
              />
            </div>
					);
        } else {
          return (
            <a
              href={node.data.uri}
              target="_blank"
              rel="noopener noreferrer"
            >
              {node.content[0].value}
            </a>
          )
        }
			}, 
      [BLOCKS.EMBEDDED_ASSET]: node => (
        <img sx={{}} src={node.data.target.fields.file["en-US"].url} alt="" />
      ),
      "asset-hyperlink": node => (
        <a
          href={node.data.target.fields.file["en-US"].url}
          target="_blank"
          rel="noopener noreferrer"
        >
          {node.content[0].value}
        </a>
      ),
      [BLOCKS.UL_LIST]: (node, children) => (
        <ul
          sx={{
            mb: 0,
            "p+&": {
              mt: -20,
              ...listStyle,
            },
          }}
        >
          {children}
        </ul>
      ),
      [BLOCKS.OL_LIST]: (node, children) => (
        <ol
          sx={{
            mb: 0,
            "p+&": {
              mt: -20,
              ...listStyle,
            },
          }}
        >
          {children}
        </ol>
      ),
      [BLOCKS.LIST_ITEM]: node => (
        // <div
        //   sx={{
        //     alignItems: "center",
        //     display: "flex",
        //     lineHeight: 1,
        //     mb: 0,
        //     position: "relative",
        //     ":last-child": { mb: 0 },
        //     "::before": {
        //       // content: '"\\2022"',
        //       fontSize: 1,
        //       position: "absolute",
        //       top: "5px",
        //       left: "-12px",
        //     },
        //     ...listItemStyle,
        //   }}
        // >

        <div className="font-helvetica">{documentToReactComponents(node)}</div>
        // {console.log("node",node,listItemStyle,listItemTextStyle)}
        // <Text customStyle={listItemTextStyle}>
        //   {node.content[0].content.map(ele=>ele.value)}
        // </Text>
        //  {/* </div> */}
      ),
    },
  }):<></>
}

RichText.propTypes = {
  data: PropTypes.shape({
    json: PropTypes.shape({
      content: PropTypes.arrayOf(
        PropTypes.shape({
          nodeType: PropTypes.string.isRequired,
        })
      ),
    }),
  }),
  textStyle: PropTypes.object,
}

export default RichText
