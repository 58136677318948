// import jsx from "theme-ui"
import React from "react"
import { graphql } from "gatsby"
import BlogData from "../components/blog-data"
import Page from "../components/page"

const BlogDetail = ({ data, location }) => {

  const renderBlogCenter = () => {
    return (
      <div>
        <BlogData
          author={data.contentfulNews.author}
          category={data.contentfulNews.category}
          date={data.contentfulNews.date}
          updated={data.contentfulNews.updated}
          fullStory={data.contentfulNews.fullStory}
          thumbImage={data.contentfulNews.image}
          subtitle={data.contentfulNews.subtitle}
          title={data.contentfulNews.title}
          teaser={data.contentfulNews.teaser}
          type={data.contentfulNews.type}
          updatedAt={data.contentfulNews.updatedAt}
          tags={data.contentfulNews.tags}
          data={data.contentfulPage}
          relatedPosts={data.contentfulNews.relatedPosts}
          quickSummary={data.contentfulNews.quickSummary}
          authorHeadshot={JSON.stringify(data.contentfulNews.authorHeadshot)}
          authorBio={data.contentfulNews.authorBio}
          pathname={location.pathname}
        />
      </div>
    )
  }

  return (
    <React.Fragment>
      <Page data={data.contentfulPage}>{renderBlogCenter()}</Page>
    </React.Fragment>
  )
}

export default BlogDetail

export const query = graphql`
  query($slug: String!) {
    contentfulPage(slug: { eq: "blog-center" }) {
      ...PageData
      ...BladePatternData
    }
    contentfulNews(slug: { eq: $slug }) {
      metaDescription
      title
      subtitle {
        id
        subtitle
      }
      teaser {
        teaser
      }
      image {
        file {
          url
        }
      }
      fullStory {
        json
      }
      type
      category
      date
      updated
      author
      tags
      authorBio {
        id
        authorBio
      }
      authorHeadshot {
        id
        file {
          url
        }
      }
      quickSummary {
        json
      }
      relatedPosts {
        anchorLink
        title
        slug
        description {
          description
        }
      }
    }
  }
`
// relatedPosts {
//   anchorLink
//   title
//   slug
//   description {
//     description
//   }
// }
