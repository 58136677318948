/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import Container from "./container"
import Text from "./text"
import Row from "./row"
import Column from "./column"
import theme from "../gatsby-plugin-theme-ui/index"
import { Button } from "@theme-ui/components"
import PageLink from "./page-link"
import CTA from "./cta"
import RichText from "./rich-text"
import NewsletterForm from "./newsletter-form"
import { findBySlug } from "../helpers"
import shareLink from "../images/facebook.svg"
import twitterLogo from "../images/twitter.svg"
import copyLinkImg from "../images/copy-link.svg"
import { useIsClient } from "../hooks/use-is-client"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

const BlogData = ({
  author,
  category,
  date,
  fullStory,
  thumbImage,
  subtitle,
  title,
  teaser,
  type,
  updated,
  tags,
  data,
  quickSummary,
  authorHeadshot,
  authorBio,
  relatedPosts,
  pathname,
}) => {
  let isClient = useIsClient()
  const socialLinkList = [
    {
      id: "twitter-link",
      alt: "Link to Twitter",
      href: `https://twitter.com/intent/tweet?text=${title}&url=${encodeURI(
        `${(isClient && window.location.href) ||
          `https://www.plantprefab.com${pathname}`}`
      )}`,
      src: twitterLogo,
    },
    {
      id: "share-link",
      alt: "Share",
      href: `https://www.facebook.com/sharer/sharer.php?u=${encodeURI(
        `${(isClient && window.location.href) ||
          `https://www.plantprefab.com${pathname}`}`
      )}`,
      src: shareLink,
    },
    {
      id: "copy-link",
      alt: "Copy Link",
      messageOnClick: "Copied to clipboard",
      action: () => {
        const text = window.location.href
        var textArea = document.createElement("textarea")
        textArea.value = text

        // Avoid scrolling to bottom
        textArea.style.top = "0"
        textArea.style.left = "0"
        textArea.style.position = "fixed"

        document.body.appendChild(textArea)
        textArea.focus()
        textArea.select()
        document.execCommand("copy")
        document.body.removeChild(textArea)
      },
      src: copyLinkImg,
    },
  ]
  const styleList = {
    socialLinkList: {
      item: {
        flexShrink: 0,
        m: 0,
        mr: 3,
        ":last-child": { mr: 0 },
      },
    },
  }

  const [showContact, setShowContact] = React.useState(false)
  const [showMessage, setShowMessage] = React.useState({})

  const renderLeftSidebar = () => {
    const bladePatternData = findBySlug({
      list: data.bladePatternList,
      slug: "blog-center-left-sidebar-module",
    })

    const Item = ({ text, link, linkType, showIcon }) => (
      <PageLink
        link={link}
        type={linkType}
        customStyle={{
          cursor: "pointer",
          display: "block",
          pb: 2,
          px: 0,
          mb: 0,
          mr: 0,
          userSelect: "none",
          overflowX: "auto",
          ":last-child": {
            pr: 0,
          },
          "-webkit-tap-highlight-color": "transparent",
          background: "white",
          color: text === category ? ["primary"] : "text",
          //   borderBottom: theme => [
          //   `1px solid ${
          //     text === category ? theme.colors.primary : "transparent"
          //   }`,
          //   `1px solid ${
          //     text === category ? theme.colors.primary : "transparent"
          //   }`,
          //   `1px solid ${
          //     text === category ? theme.colors.primary : "transparent"
          //   }`,
          //   "0px",
          // ],
          ":hover": {
            color: "primary",
          },
          fontWeight: "300",
          fontSize: 3,
          fontFamily: "body",
          ...(showIcon && {
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }),
        }}
      >
        {text}
      </PageLink>
    )
    return (
      <div>
        <div
          style={{
            whiteSpace: "nowrap",
            overflowX: "auto",
          }}
        >
          <Text
            customStyle={{
              color: ["text", "text", "text", "#cdcdcd"],
              fontSize: [28, 28, 28, 13],
              fontWeight: ["500"],
              pb: 2,
              display: "block",
            }}
          >
            <span sx={{ display: ["none", "none", "none", "inline"] }}>
              LEARNING CENTER
            </span>
            <span sx={{ display: ["inline", "inline", "inline", "none"] }}>
              Learning Center
            </span>
          </Text>
          {bladePatternData.contentCards.map(card => (
            <div key={card}>
              <Item
                text={card.title}
                link={
                  (card.ctaDestination && card.ctaDestination.ctaDestination) ||
                  (card.ctaDestinationExternal &&
                    card.ctaDestinationExternal.ctaDestinationExternal) ||
                  `/blog/?category=${card.title}`
                }
                linkType={card.ctaDestinationExternal ? "external" : "internal"}
              />
            </div>
          ))}
        </div>
      </div>
    )
  }

  const renderMainPanel = () => {
    const formatDate = date => {
      const format = new Date(date).toDateString().split(" ")
      return `${format[1]} ${format[2]}, ${format[3]}`
    }

    const authorImg = JSON.parse(authorHeadshot)
    return (
      <Row customStyle={{ px: 0, my: [theme => theme.spacing.horizontal, 0] }}>
        <Column>
          <Text
            type="h6"
            customStyle={{
              pb: 20,
              color: "primary",
              fontSize: "15px",
              fontWeight: "400",
            }}
          >
            PLANT BLOG
          </Text>
          <Text
            type="h2"
            customStyle={{
              pb: theme => [theme.spacing.vertical.sm],
              fontWeight: "bold",
            }}
          >
            {title && title}
          </Text>
          <Text
            type="h3"
            customStyle={{
              pb: theme => [theme.spacing.vertical.md],
              fontSize: 20,
              fontStyle: "italic",
              fontWeight: 600,
              letterSpacing: "0.06714em",
              // wordSpacing: "2px"
              fontWeight: "bold",
              // color: "#4b3f3f"
            }}
          >
            {subtitle && subtitle.subtitle}
          </Text>
          <Row>
            <Column size={[12, 8, 8]} customStyle={{ pl: ["0px", "10px"] }}>
              <Text
                customStyle={{
                  // color: "#888",
                  fontSize: [15],
                  fontWeight: ["500"],
                  pb: theme => [theme.spacing.vertical.sm],
                  display: "block",
                  float: "left",
                }}
              >
                <span style={{ color: "rgb(189 189 189)" }}>{author}</span>
                {updated ? (
                  <span style={{ color: "rgb(173 156 156)", fontWeight: 525 }}>
                    {" "}
                    | Updated: {formatDate(updated)}
                  </span>
                ) : date ? (
                  <span style={{ color: "rgb(173 156 156)", fontWeight: 525 }}>
                    {" "}
                    | Published: {formatDate(date)}
                  </span>
                ) : null}
              </Text>
            </Column>
            <Column size={[12, 4, 4]}>
              <ul
                sx={{
                  alignItems: "center",
                  display: "flex",
                  listStyle: "none",
                  justifyContent: "flex-end",
                  float: "right",
                  paddingLeft: "120px",
                  m: 0,
                  ml: 0,
                }}
              >
                {socialLinkList.map((link, index) => (
                  <li
                    sx={{
                      position: "relative",
                      ...styleList.socialLinkList.item,
                      display: "flex",
                      ...(link.messageOnClick && {
                        "::after": {
                          content: `'${link.messageOnClick}'`,
                          position: "absolute",
                          top: showMessage[index]
                            ? ["-2px", "-2px", 28]
                            : ["-2px", "-2px", 20],
                          right: ["unset", "unset", 0],
                          left: showMessage[index]
                            ? [28, 28, "unset"]
                            : [24, 24, "unset"],
                          fontSize: 16,
                          whiteSpace: "nowrap",
                          fontFamily: "body",
                          color: "#777",
                          transition: "0.5s",
                          opacity: showMessage[index] ? "1" : "0",
                          pointerEvents: "none",
                        },
                      }),
                    }}
                    key={link.id}
                  >
                    <PageLink
                      customStyle={{
                        display: "block",
                        pr: 0,
                        cursor: "pointer",
                      }}
                      link={link.href}
                      onClick={() => {
                        setShowMessage({ ...showMessage, [index]: true })
                        setTimeout(
                          () =>
                            setShowMessage({ ...showMessage, [index]: false }),
                          2500
                        )
                        link && link.action()
                      }}
                    >
                      <img
                        sx={{ display: "block", width: 20 }}
                        src={link.src}
                        alt={link.alt}
                      />
                    </PageLink>
                  </li>
                ))}
              </ul>
            </Column>
          </Row>
          {/* { thumbImage && <img 
                    src={thumbImage.file.url} 
                    sx={{my:theme=>[theme.spacing.horizontal]}}
                />} */}
          {quickSummary && (
            <div
              id="page-blade-pattern-world-class-design-gallery"
              sx={{
                backgroundColor: theme => theme.colors.backgrounds.primary,
                // p: theme => [
                //   theme.spacing.vertical.sm,
                //   theme.spacing.vertical.sm,
                // ],
                my: 3,
              }}
            >
              {/* <div className="font-helvetica">
                     {documentToReactComponents(quickSummary.json)}
                   </div> */}

              <RichText data={quickSummary} />
            </div>
          )}
          {fullStory && (
            <div
              sx={{
                py: theme => theme.spacing.vertical.sm,
                // fontFamily:"Helvetica Neue,sans-serif"
              }}
            >
              {/* {console.log("fullstory",fullStory)} */}
              <RichText data={fullStory} pathname={pathname}/>
              {/* <div className="font-helvetica">
                     {documentToReactComponents(fullStory.json)}
                   </div> */}
            </div>
          )}

          <Row>
            {tags &&
              tags.split(",").map(ele => (
                <div
                  // size={[12,12,0]}
                  sx={{
                    backgroundColor: "#f2f2ef",
                    m: 1,
                    fontFamily: "Helvetica Neue,sans-serif",
                    fontWeight: "600",
                    fontSize: 15,
                    maxWidth: "max-content",
                    borderRadius: "4px",
                    py: 1,
                    px: 3,
                  }}
                >
                  {ele}
                </div>
              ))}
          </Row>

          {
            <Row
              customStyle={{
                mt: theme => [theme.spacing.vertical.sm],
              }}
            >
              <Column
                size={[12, 3]}
                customStyle={{
                  mb: theme => [
                    theme.spacing.vertical.sm,
                    theme.spacing.vertical.sm,
                    theme.spacing.vertical.md,
                  ],
                  cursor: "pointer",
                }}
              >
                {authorImg && (
                  <div
                    sx={{
                      maxWidth: [300, 200, 150],
                      m: [4, 2, 0],
                    }}
                  >
                    <img
                      src={authorImg.file.url}
                      alt="no img"
                      sx={{
                        // m: 2,
                        width: "100%",
                        height: "auto",
                        borderRadius: "50%",
                      }}
                    />
                  </div>
                )}
              </Column>
              <Column
                size={[12, 9]}
                customStyle={{
                  mb: theme => [
                    theme.spacing.vertical.sm,
                    theme.spacing.vertical.sm,
                    theme.spacing.vertical.md,
                  ],
                  cursor: "pointer",
                }}
              >
                {authorBio && (
                  <>
                    {" "}
                    <Text
                      customStyle={{
                        color: ["#aca2a2", "#cdcdcd"],
                        fontSize: [15, 15, 15, 12],
                        fontWeight: ["400"],
                        pb: 2,
                        display: "block",
                      }}
                    >
                      ABOUT THE AUTHOR
                    </Text>
                    <Text
                      customStyle={{
                        fontStyle: "italic",
                        fontWeight: [400],
                      }}
                    >
                      {authorBio.authorBio}
                    </Text>
                  </>
                )}
              </Column>
            </Row>
          }
          {relatedPosts && (
            <Text
              customStyle={{
                color: ["#888", "#cfc9c9"],
                fontSize: [15, 15, 15, 13],
                fontWeight: ["500"],
                pb: 2,
                mb: 3,
                display: "block",
              }}
            >
              RELATED POSTS
            </Text>
          )}
          {relatedPosts &&
            relatedPosts.map(obj => (
              <PageLink
                link={`/blog/${obj.slug}`}
                type={"internal"}
                customStyle={{
                  cursor: "pointer",
                  display: "block",
                  // pb: 2,
                  px: 0,
                  mb: 4,
                  mr: 0,
                  userSelect: "none",
                  overflowX: "auto",
                  ":last-child": {
                    pr: 0,
                  },
                  "-webkit-tap-highlight-color": "transparent",
                  background: "white",
                  color: "primary",
                  ":hover": {
                    color: "primary",
                  },
                  fontWeight: "300",
                  fontSize: 3,
                  fontFamily: "body",
                }}
              >
                <Text type="h3" customStyle={{ pb: 2 }}>
                  {obj.title}
                </Text>
                <Text
                  customStyle={{
                    color: ["#888", "#b5b2b2"],
                    fontSize: [15],
                    fontWeight: ["500"],
                    // pb: 2,
                    display: "block",
                  }}
                >
                  {obj.description && obj.description.description}
                </Text>
              </PageLink>
            ))}
          {relatedPosts && (
            <PageLink
              link={`/blog`}
              type={"internal"}
              customStyle={{
                cursor: "pointer",
                display: "block",
                pb: 2,
                px: 0,
                mb: 0,
                mr: 0,
                mt: 2,
                userSelect: "none",
                overflowX: "auto",
                ":last-child": {
                  pr: 0,
                },
                "-webkit-tap-highlight-color": "transparent",
                background: "white",
                color: "primary",
                ":hover": {
                  color: "primary",
                },
                fontWeight: "300",
                fontSize: 3,
                fontFamily: "body",
              }}
            >
              All Articles {">"}
            </PageLink>
          )}
        </Column>
      </Row>
    )
  }
  const renderRightSidebar = () => {
    let JoinData = {
      description: {
        nodeType: "document",
        json: {
          data: {},
          content: [
            {
              data: {},
              content: [
                {
                  data: {},
                  marks: [],
                  value:
                    "Sign up for our monthly newsletter to receive new posts, news, and more.\n",
                  nodeType: "text",
                },
              ],
              nodeType: "paragraph",
            },
          ],
          nodeType: "document",
        },
        description:
          '{"data":{},"content":[{"data":{},"content":[{"data":{},"marks":[],"value":"Sign up for our monthly newsletter to receive new posts, news, and more.\\n\\n","nodeType":"text"}],"nodeType":"paragraph"}],"nodeType":"document"}',
      },
    }
    let Contactus = {
      description: {
        nodeType: "document",
        json: {
          data: {},
          content: [
            {
              data: {},
              content: [
                {
                  data: {},
                  marks: [],
                  value:
                    " Learn how Plant can help you build a higher-quality home, faster and more efficiently than any other builder.\n",
                  nodeType: "text",
                },
              ],
              nodeType: "paragraph",
            },
          ],
          nodeType: "document",
        },
        description:
          '{"data":{},"content":[{"data":{},"content":[{"data":{},"marks":[],"value":" Learn how Plant can help you build a higherquality home, faster and more efficiently than any other builder.\\n\\n","nodeType":"text"}],"nodeType":"paragraph"}],"nodeType":"document"}',
      },
    }

    return (
      <Row
        customStyle={{
          pl: [0, 0, 0, 6],
          position: ["relative", "sticky"],
          top: "0px",
        }}
      >
        <Column>
          <div sx={{ pb: [0, 0, 0, 5] }}>
            <Text
              type="h3"
              customStyle={{ pb: 20, fontSize: 28, fontWeight: "500" }}
            >
              Contact Us
            </Text>
            <RichText
              data={Contactus.description}
              textStyle={{ fontFamily: "body" }}
            ></RichText>
            <CTA
              link={"/contact"}
              linkType={"internal"}
              customStyle={{ fontSize: 2, py: 2, pl: 3, mt: [3, 1, 0] }}
            >
              Contact Us
            </CTA>
            <Text
              type="h3"
              customStyle={{ pb: 20, mt: 50, fontSize: 28, fontWeight: "500" }}
            >
              Join Our Newsletter
            </Text>
            <RichText
              data={JoinData.description}
              textStyle={{ fontFamily: "body" }}
            ></RichText>
            <Button
              sx={{
                ...theme.forms.buttonChevron,
                fontSize: 14,
                pr: 7,
                py: "5px",
                pl: 19,
                // mt: 26,
                display: "flex",
                justifyContent: "space-between",
                width: 190,
                color: "primary",
                border: theme => `2px solid ${theme.colors.primary}`,
                background: "transparent",
                whiteSpace: "nowrap",
                ":hover": {
                  border: theme => `2px solid ${theme.colors.secondary}`,
                  color: "secondary",
                },
              }}
              onClick={() => setShowContact(true)}
            >
              Join Our Newsletter
            </Button>
          </div>
        </Column>
      </Row>
    )
  }

  return (
    <React.Fragment>
      <div
        sx={{
          my: theme => [
            theme.spacing.vertical.sm,
            theme.spacing.vertical.sm,
            theme.spacing.vertical.md,
          ],
          pl: [20, 0, 0],
        }}
      >
        <Container>
          {showContact && (
            <NewsletterForm onClose={() => setShowContact(false)} />
          )}
          {/* <div style={{ display: ["block", "block", "block", "flex"] }}>
          <Row customStyle={{ width: "100%", 
            // pl: [0, 0, 0, "75px"] 
            }}>
            <Column size={[3]}
                 customStyle={{
                  display: ["none", "none", "none", "block"],
                }}
            >
            {renderLeftSidebar()}
            </Column>
            <Column size={[12, 12, 12, 6]}>
            {renderMainPanel()}
            </Column>
            <Column size={[12, 12, 12, 3]} 
                customStyle={{ mt: [0,5] , 
                // pr:[0,5]
            }}>
            {renderRightSidebar()}
            </Column>
            <Column
              size={[12]}
              customStyle={{
                display: ["block", "block", "block", "none"],
                mt: 6,
              }}
            >
              {renderLeftSidebar()}
            </Column>
          </Row>
        </div> */}
          <div sx={{ display: ["block", "block", "block", "flex"] }}>
            <div
              sx={{
                width: "265px",
                display: ["none", "none", "none", "block"],
              }}
            >
              {renderLeftSidebar()}
            </div>
            <Row customStyle={{ width: "100%", pl: [0, 0, 0, "75px"] }}>
              <Column size={[12, 12, 12, 7]}>{renderMainPanel()}</Column>
              <Column size={[12, 12, 12, 5]} customStyle={{ mt: [0, 5] }}>
                {renderRightSidebar()}
              </Column>
              <Column
                size={[12]}
                customStyle={{
                  display: ["block", "block", "block", "none"],
                  mt: 6,
                }}
              >
                {renderLeftSidebar()}
              </Column>
            </Row>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default BlogData
